/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
	
	&__links ul {
		display: flex;
		align-items: center;
		font-size: 14px;
	}

	&__links li {

		+ li {
			margin-left: 36px;
		}
	}

	&__links a {
		color: #fff;
		display: block;
		transition: color .3s;

		&:hover {
			color: $c-green-3B;
		}
	}

	&__copyright {
		opacity: .5;
		font-size: 13px;
		margin-left: 36px;
	}
}
