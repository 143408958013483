/* ------------------------------------------------------------ *\
	Card-Links
\* ------------------------------------------------------------ */

.card-links__title {
  font-size: 1vw;
  margin-bottom: 1vw;
}

.card-links {
  max-width: 980px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @media (max-width: 767px) {
    grid-template-columns: auto;
    padding: 20px;
  }

  .loader {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__go-back {
    text-align: center;
    grid-row: 1;
    grid-column: 1 / 5;

    h4 {
      font-size: 1.2vw;
      margin-bottom: 1vw;
    }

    a {
      background: $c-green-3B;
      padding: 0.5vw 1vw;
      border-radius: 13px;

      &:hover {
        background: $c-violet-72;
      }
    }
  }
  &--large {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    @media (max-width: 767px) {
      grid-template-columns: auto;
      padding: 13px;
      margin-bottom: 150px;
      width: 100%;
    }
  }
  &--padded-bottom {
    padding-bottom: 60px;
  }

  .card-link {
    background-size: cover;
  }

  &--large .card-link {
    height: 300px;
    // padding: 28px 24px 16%;
  }

  &--small {
    display: grid;
    grid-template: auto / 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 0 0 60px;
  }

  &--small .card-link {
    width: 100%;
    height: 23vh;
    width: 23vh;
    margin: 0;
    padding: 1.2vw 1.2vw 15%;
  }
}

/* ------------------------------------------------------------ *\
	Card-Link
\* ------------------------------------------------------------ */

.card-link {
  border-radius: 13px;
  // padding: 1.4vw 1.4vw;
  padding: 7%;
  height: auto;
  display: block;
  height: 230px;

  transition: transform 0.4s;
  position: relative;
  overflow: hidden;

  > a {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
  }

  &__graphic {
    position: absolute;
    z-index: 0;
    // mix-blend-mode: overlay;

    img {
      width: 100%;
    }
  }

  &__graphic.right {
    right: -6rem;
  }

  &__graphic.left {
    left: -10px;
  }

  &__graphic.center {
    width: 94%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__graphic.bottom {
    bottom: 0;
  }

  &__graphic.top {
    top: 0;
  }

  &__content {
    position: relative;
    z-index: 4;
    pointer-events: none;
    @media (max-width: 768px) {
      white-space: pre-wrap;
    }
  }

  &__cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    z-index: 0;
  }

  &:hover {
    transform: translateY(-4px);
  }

  h4 {
    @media (max-width: 820px) {
      font-size: 1.1rem;
    }
    font-size: 22px;
    margin-bottom: 18px;
    position: relative;
    user-select: none;
  }

  h5 {
    font-size: 16px;
    position: relative;
    user-select: none;
    margin-bottom: 5px;
  }

  &__entry {
    font-size: 16px;
    line-height: 19.5px;
    position: relative;
  }
}

/* ------------------------------------------------------------ *\
	Card-Link--Vertical
\* ------------------------------------------------------------ */

.card-link--vertical {
  display: flex;
  flex-direction: column-reverse;
  text-align: left;
  padding: 0;
  // height: auto;
  height: 100%;

  .card-link__content {
    padding: 20px;
  }

  .card-link__graphic {
    position: relative;
    padding-bottom: 70%;
    width: 100%;
    mix-blend-mode: normal;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
