/* ------------------------------------------------------------ *\
	System-Details
\* ------------------------------------------------------------ */

.system-details {
  margin-bottom: 50px;

  @media (max-width: 767px) {
    margin: 0px;
    padding: 10px 20px;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }

  > h4 {
    margin-bottom: 20px;
    font-size: 16px;
  }
}

.system-details-qsys {
  margin-bottom: 50px;

  @media (max-width: 767px) {
    margin: 0px;
    padding: 10px 20px;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
    
    @media (max-width: 820px) {
      grid-template-columns: repeat(2, 1fr);
    }
    
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  > h4 {
    margin-bottom: 20px;
    font-size: 16px;
  }
}

/* ------------------------------------------------------------ *\
	System-Detail
\* ------------------------------------------------------------ */

.system-detail {
  height: 100%;
  width: calc(50% - 50px);
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(#fff, 0.5);

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 15px;
    padding-bottom: 25px;
  }

  &__icon {
    margin-right: 40px;
    display: block;
  }

  &__content h4 {
    font-weight: 700;
    margin-bottom: 4px;
    font-size: 16px;
  }

  &__content h6 {
    font-weight: 400;
    margin-bottom: 4px;
    font-size: 12px;
  }
}

.system-detail-qsys {
  height: 100%;
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(#fff, 0.5);

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 15px;
    padding-bottom: 25px;
  }

  &__content h4 {
    font-weight: 700;
    margin-bottom: 4px;
    font-size: 16px;
  }

  &__content h6 {
    font-weight: 400;
    margin-bottom: 4px;
    font-size: 12px;
  }
}

.system-detail-profile {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 15px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(#fff, 0.5);

  &__icon {
    margin-bottom: auto;
    margin-top: 10px;
    margin-right: 40px;
    display: block;
  }

  &__content h4 {
    font-weight: 700;
    margin-bottom: 4px;
    font-size: 16px;
  }

  &__content h6 {
    font-weight: 400;
    margin-bottom: 4px;
    font-size: 12px;
  }
}
