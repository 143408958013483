/* ------------------------------------------------------------ *\
	Page
\* ------------------------------------------------------------ */
.ticker {
  width: 100%;
  // margin-left: 8px;
}

.page {
  height: 100vh;
  width: 100%;
  position: relative;

  &__background {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
  }

  &__background-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-position: center center;
    filter: opacity(0.06);
    background-image: url('../../assets/svg-icons/background-T.svg');
  }
  .page__body {
    margin-bottom: 80px;
    width: 100%;



  
  }

  .page__head {
    @media (max-width: 767px) {
      display: flex;
      justify-content: space-between;
      padding: 50px 13px 30px;
      position: static;
    }
    width: 100%;


    position: absolute;
    top: -105px;
    left: 0;
  }

  .page__head h5 {
    font-size: 12px;
    font-weight: 400;
  }

  .page__head h2 {
    font-size: 29px;
    font-weight: 700;
  }
}

/* ------------------------------------------------------------ *\
	Page-Login
\* ------------------------------------------------------------ */

.page--login {
  .page__background {
    background: $c-violet-72;
  }

  .page__background-image {
    width: 55%;
  }
}

.mobile-auth {
  display: none;
}

@media (max-width: 767px) {
  .mobile-auth {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px);
  }
  .page--login {
    .page__background {
      background: #72029d;
    }
    .page__background-image {
      display: none;
    }
    .login {
      flex: 1;

      background: #030416;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 13px;
      .btn {
        margin-top: 15px;
      }
      .login-footer {
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .logo-container {
        text-align: center;
        margin-top: 50px;
      }
    }
  }
}

/* ------------------------------------------------------------ *\
	Page-Devices
\* ------------------------------------------------------------ */

.page--devices {
  .page__background {
    background: $c-dark-03;
  }

  .page__background-image {
    width: 700%;
    background-size: 160% 160%;
  }
}

/* ------------------------------------------------------------ *\
	Page-Dashoard
\* ------------------------------------------------------------ */

.page--dashboard {
  .page__background {
    background: $c-violet-72;
  }

  .page__background-image {
    width: 700%;
    background-size: 160% 160%;
  }
}

/* ------------------------------------------------------------ *\
	Page--Highlight
\* ------------------------------------------------------------ */

.page--highlights {
  @media (max-width: 767px) {
    padding: 10px;
  }
  .page__background {
    background: $c-violet-72;
  }

  .page__background-image {
    width: 700%;
    background-size: 160% 160%;
  }
}

/* ------------------------------------------------------------ *\
	Page--Offline
\* ------------------------------------------------------------ */

.page--offline {
  .page__background {
    background: #262735;
  }
  .page__background-image {
    width: 700%;
    background-size: 160% 160%;
  }
}

/* ------------------------------------------------------------ *\
	Page--Music
\* ------------------------------------------------------------ */

.page--music {
  .page__background {
    background: $c-dark-03;
  }

  .page__background-image {
    width: 700%;
    background-size: 160% 160%;
  }
}

.page--support {
  .page__background {
    background: $c-dark-03;
  }

  .page__background-image {
    width: 700%;
    background-size: 160% 160%;
  }
}

.page--music-search {
  &__body {
    margin-right: -50px;
  }
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .page__background {
    background: $c-dark-03;
  }

  .page__background-image {
    width: 700%;
    background-size: 160% 160%;
  }
}

.page--device-info {
  .page__background {
    background: $c-dark-03;
  }
  .page__background-image {
    width: 700%;
    background-size: 160% 160%;
  }
}

.page--profile {
  .page__background {
    background: $c-dark-03;
  }
  .page__background-image {
    width: 700%;
    background-size: 160% 160%;
  }
}

.page--current-playlist {
  .page__background {
    background: $c-dark-03;
  }
  .page__background-image {
    width: 700%;
    background-size: 160% 160%;
  }
}

.page--schedule {
  .page__background {
    background: $c-dark-03;
  }
  .page__background-image {
    width: 700%;
    background-size: 160% 160%;
  }
}
