/* ------------------------------------------------------------ *\
	Dashboard
\* ------------------------------------------------------------ */

.dashboard {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 10vw 10vw 60px;

  overflow-y: auto;

  .mobile-icon {
    @media (min-width: 767px) {
      display: none;
    }
    @media (max-width: 767px) {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 10;
    }
  }

  .playlist-play-now {
    @media (min-width: 767px) {
      display: none;
    }
    @media (max-width: 767px) {
      width: 132px;
      height: 45px;
      font-size: 12px;
      border-radius: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      bottom: 132px;
      right: 10px;
      z-index: 10;
      padding: 0;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 9rem 2rem 60px;
  }
  @media (max-width: 767px) {
    padding: 0px;
    overflow: auto;
  }

  &__sidebar {
    @media (max-width: 767px) {
      display: none;
    }
    @media (max-width: 1024px) {
      width: auto;
      margin-right: 40px;
    }
    width: 18%;
    top: 20px;
    margin-right: 20px;
    position: sticky;
    min-width: fit-content;
  }

  &__sidebar ul {
    list-style: none;
    color: #fff;
    font-size: 13px;
    margin-bottom: 258px;
    flex: 1;
  }

  &__sidebar ul li {
    &:first-child {
      border-bottom: 1px solid rgba(#fff, 0.25);
      padding-bottom: 28px;
    }

    + li {
      margin-top: 32px;
    }
  }

  &__sidebar_text {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__sidebar ul a,
  &__sidebar ul button {
    display: flex;
    align-items: center;
    opacity: 0.7;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }

    svg {
      @media (max-width: 1024px) {
        margin-right: 0px;
      }
      display: block;
      margin-right: 10px;
    }
  }

  &__content {
    width: 100%;
    max-width: 980px;
    @media (max-width: 768px) {
      width: 100vw;
    }
  }

  &__content .page {
    height: auto;
  }

  .is-current {
    opacity: 1;
  }
}
