/* ------------------------------------------------------------ *\
	Modal
\* ------------------------------------------------------------ */

.modal {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(3, 4, 22, 0.9);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: fadeIn 1s;
  text-align: left;
  overflow-y: auto;
  padding: 20px;

  @media (max-width: 767px) {
    z-index: 9999;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__inner {
    width: 100%;
    height: 100%;
    max-width: 375px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // @media (max-width: 767px) {
    //   height: 100%;
    // }
  }

  &__head {
    margin-bottom: 55px;
  }

  &__body {
    font-size: 12px;
    line-height: 19.5px;
    padding-bottom: 50px;
    @media (max-width: 767px) {
      padding-bottom: 50px;
    }

    p {
      margin-bottom: 28px;
    }
  }

  h5 {
    font-size: 12px;
    font-weight: 400;
  }

  h2 {
    font-size: 29px;
  }

  &:after {
    position: fixed;
    width: 101vw;
    height: 101vh;
    background: rgba(#000, 0.6);
  }
}

/* ------------------------------------------------------------ *\
	Modal-Status
\* ------------------------------------------------------------ */

.modal-status {
  width: 375px;
  background: $c-dark-03;
  border-radius: 3px;
  padding: 17px 13px;
  position: fixed;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.33);
  z-index: 4;
  animation: slideUpCenter 2s;

  &__close {
    position: absolute;
    right: 14px;
    top: 14px;
    background: 0;
    border: 0;
  }

  h4 {
    font-size: 16px;
    width: 87%;

    button {
      font-weight: 700;
      border: 0;
      border-bottom: 1px solid #fff;
      display: inline;
      background: 0;
    }
  }

  h6 {
    color: $c-yellow-FF;
    font-size: 13px;
    margin-bottom: 5px;
  }

  &--disconnected h6 {
    color: $c-red-FF;
  }
}

/* ------------------------------------------------------------ *\
	Modal-window
\* ------------------------------------------------------------ */

.modal-window {
  &__container {
    width: 313px;
    align-items: center;
    background: #3baba1;
    border-radius: 13px;
    margin: auto;
  }

  &__boarding {
    width: 313px;
    height: 470px;
    align-items: center;
    background: #ffffff;
    border-radius: 13px;
    margin: auto;
    position: relative;
  }

  &__imgcontainer {
    position: relative;
    width: 254px;
    height: 215px;
    padding: 20px 32px;
    margin: auto;
  }

  &__graphics1 {
    position: absolute;
    width: 254px;
    height: 209px;
    left: 0.5px;
    top: 20px;
    z-index: 2;
  }

  &__graphics2 {
    position: absolute;
    width: 198px;
    height: 198px;
    left: 29.5px;
    top: 37px;
    z-index: 1;
  }

  &__title {
    margin: 57px 0px;
    font-family: Tondo;
    font-weight: 900;
    text-align-last: center;
    font-size: 23px;
    line-height: 24px;
  }

  &__subtitle {
    margin: 32px 0px;
    font-family: Tondo;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    padding: 0px 25px 0px 25px;
  }

  &__title-play-now {
    margin: 0px 0px;
    font-family: Tondo;
    font-size: 12px;
    line-height: 19px;
    text-align: left;
    padding: 0px 15px;
    color: #030416;
  }

  &__subtitle-play-now {
    margin: 0px 0px;
    font-family: Tondo;
    font-size: 29px;
    font-weight: 700;
    line-height: 19px;
    text-align: left;
    padding: 0px 15px;
    color: #030416;
  }
  &__device-count-play-now {
    margin: 0px 0px;
    font-family: Tondo;
    font-size: 13px;
    line-height: 20px;
    text-align: left;
    padding: 0px 15px;
    color: #aaaaaa;
  }
}

.modal-boarding {
  padding: 0px 13px 0px 13px;
  &__subtitle {
    color: #030416;
    font-size: 12px;
    margin-bottom: 0px !important;
  }
  &__title {
    font-size: 29px;
    color: #030416;
    font-weight: 700;
  }
  &__info {
    font-size: 13px;
    color: #aaaaaa;
    margin-top: 21px;
  }
  &__input {
    color: #030416;
    background-color: #ebebeb;
    border: none;
    height: 49px;
    width: 100%;
    border-radius: 13px;
    padding: 15px;
    font-size: 13px;
  }
  &__input:focus {
    background-color: transparent;
    border: 1px solid #3baba4;
    font-size: 13px;
  }
  &__input::placeholder {
    font-size: 13px;
    color: #aaaaaa;
  }
}
