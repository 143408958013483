/* ------------------------------------------------------------ *\
	Player
\* ------------------------------------------------------------ */

.player-container {
  @media (max-width: 767px) {
    width: 100%;
    padding: 13px;
    position: relative;
  }
}

.player {
  @media (max-width: 1025px) {
    height: 430px;
  }
  @media (max-width: 768px) {
    height: 315px;
  }

  height: 490px;
  display: flex;
  background: $c-dark-03;
  border-radius: 13px;
  overflow: hidden;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    height: 100%;
    flex-direction: column;
  }

  .loader {
    margin: 230px auto;
  }

  &__image {
    width: 50%;
    overflow: hidden;
    position: relative;
    padding-bottom: 40%;

    @media (max-width: 767px) {
      width: 100%;
      padding-bottom: 0px;
    }

    img {
      @media (max-width: 767px) {
        position: inherit;
        object-fit: contain;
      }
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: fill;
      transition: transform 0.4s;
    }
  }

  &__content {
    @media (max-width: 767px) {
      width: 100%;
      padding: 30px 20px 20px 30px;
    }
    width: 50%;
    padding: 57px 40px 40px;
    display: flex;
    flex-direction: column;
  }

  &__description {
    font-size: 12px;
    flex: 1;
  }

  &__description p {
    margin-bottom: 5px;
  }

  &__feedback {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }

  &__feedback .btn:not(.btn--as-link) {
    // padding: 1.2vw;
    // border-radius: 0.7vw;
    // font-size: 0.7vw;
  }

  &__feedback-text {
    display: flex;
    align-items: center;
    font-size: 13px;
    @media (max-width: 767px) {
      display: none;
    }
  }

  &__feedback-text img {
    margin-right: 10px;
    width: 2.8vw;
  }

  h5 {
    color: $c-green-3B;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 7px;
  }

  h2 {
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 18px;
  }
}

/* ------------------------------------------------------------ *\
	Progress-Range
\* ------------------------------------------------------------ */

.progress-range {
  display: flex;
  align-items: center;
  justify-content: center;

  .rangediv {
    @media (max-width: 767px) {
      width: 100%;
    }
    width: 40%;
  }

  button {
    background: 0;
    border: none;
    display: block;
    margin-left: 35px;
    transition: transform 0.2s;

    svg path {
      transition: stroke 0.5s;
    }

    &:hover svg path,
    &:active svg path {
      stroke: #fff;
    }

    &:active {
      transform: scale(0.8);
    }
  }
}
