/* ------------------------------------------------------------ *\
	List-Results
\* ------------------------------------------------------------ */

.square {
  position: relative;
  box-sizing: border-box;
  max-height: 300px;
}

.square::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.square .content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.list-results {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 980px;
}

.list-results-small {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 980px;
}

.list-results-mobile {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  margin: 13px;
  margin-bottom: 135px;
}

/* ------------------------------------------------------------ *\
	Result-Item
\* ------------------------------------------------------------ */

.result-item {
  display: block;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(#fff, 0.25);
  border-radius: 0;
  break-inside: avoid;
  page-break-inside: avoid;

  @media (max-width: 767px) {
    border-bottom: 0px;
  }

  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin: 0 10px 4px;
  }

  > a {
    display: flex;
    align-items: center;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__image {
    min-width: 48px;
    height: 48px;
    margin-right: 10px;
    border-radius: 3px;
    position: relative;

    @media (max-width: 767px) {
      min-width: 94px;
      height: 94px;
    }

    img {
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      @media (max-width: 767px) {
        border-radius: 3px;
      }
    }
  }

  span {
    display: block;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 4px;
  }

  &__description {
    opacity: 0.5;
    font-weight: 400;
    @media (max-width: 767px) {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 4; /* number of lines to show */
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}
