/* ------------------------------------------------------------ *\
	Users-List
\* ------------------------------------------------------------ */

.users-list {
  > h4 {
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    margin: 0px 13px 220px;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -10px;
    @media (max-width: 767px) {
      margin: 0px;
    }
  }

  .user {
    width: calc(33.33% - 20px);
    margin: 0 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(#fff, 0.5);
    @media (max-width: 767px) {
      width: 44%;
    }
  }

  &__gravatar {
    position: absolute;
    left: 0;
    top: 0;
  }
}
