.sonos-install-desktop {
  position: relative;
  @media (max-width: 767px) {
    display: none;
  }
}

.sonos-install-mobile {
  position: relative;
  //   height: 685px;
  margin-bottom: 100px;
  @media (min-width: 767px) {
    display: none;
  }
}
