/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

$font-family-tondo: 'Tondo', sans-serif;

$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-17: 17px;
$font-size-18: 18px;

$line-height-base: 1.2;
$font-size-base: $font-size-15;
