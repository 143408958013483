/* ------------------------------------------------------------ *\
	Highlight
\* ------------------------------------------------------------ */

.highlight {
  &__image {
    overflow: hidden;
    border-radius: 13px;
    position: relative;
    padding-bottom: 65%;
    margin-bottom: 30px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.4s;
    }
  }

  &__entry {
    max-width: 480px;
    margin: 0 auto;
    font-size: 13px;
  }

  iframe {
    width: 100%;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
    font-size: inherit;
  }

  &__entry h3 {
    font-size: 1.2vw;
    margin-bottom: 20px;
  }
}
