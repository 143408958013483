/* ------------------------------------------------------------ *\
	Section-Search-Result
\* ------------------------------------------------------------ */

.section-search-result {
  // width: 980px;
  width: 100%;
  + .section-search-result {
    margin-top: 64px;
  }
  @media (max-width: 767px) {
    width: 100vw;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__head h4 {
    font-size: 16px;
  }

  &__head .btn {
    font-size: 16px;
  }

  &__head > a {
    color: $c-green-3B;
    font-size: 16px;
  }
  &__items {
    @media (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
    max-width: 980px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-bottom: 35px;
  }
}
