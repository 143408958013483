/* ------------------------------------------------------------ *\
	Emoji-Field
\* ------------------------------------------------------------ */

.emoji-field {
	border-radius: 13px;
	padding: 12px;
	position: relative;
	border: 2px solid transparent;
	transition: transform .4s, filter .4s;

	&:hover {
		transform: translateY(-3px);
	}

	input {
		position: absolute;
		width: 100%;
		height: 100%;
		cursor: pointer;
		left: 0;
		top: 0;
		appearance: none;
	}

	label {
		display: flex;
		align-items: center;
	}

	label img {
		display: block;
		margin-right: 20px;
	}
}
