/* ------------------------------------------------------------ *\
	Powered-By
\* ------------------------------------------------------------ */

.powered-by {
  position: sticky;
  bottom: 0px;
  h4 {
    font-size: 12px;
    margin-bottom: 6px;
    font-weight: 400;
  }
}
