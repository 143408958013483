/* ------------------------------------------------------------ *\
	Animations
\* ------------------------------------------------------------ */

@keyframes slideUpCenter {
	0% {
		opacity: 0;
		transform: translateY(40px) translateX(-50%);
	}

	100% {
		opacity: 1;
		transform: translateY(0) translateX(-50%);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes expandUp {
	0%, 100% {
		transform: scaleY(1);
	}

	50% {
		transform: scaleY(1.14);
	}
}

@keyframes moveTop {
	0%,
	100% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-5px);
	}
}
