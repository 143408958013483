/* ------------------------------------------------------------ *\
	User
\* ------------------------------------------------------------ */

.user {
	display: flex;
	align-items: center;

	&__image {
		position: relative;
		width: 48px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		border-radius: 3px;
		overflow: hidden;

		img {
			object-fit: cover;
			object-position: center center;
			width: 100%;
			height: 100%;
		}

		h6 {
			font-size: 20px;
			pointer-events: none;
			user-select: none;
		}
	}

	h5 {
		opacity: .5;
		font-size: 12px;
		font-weight: 400;
	}

	&.is-online h2 {
		display: flex;
		align-items: center;

		&:after {
			content: '';
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: $c-green-40;
			display: block;
			margin-left: 10px;
		}
	}

	&__content h2 {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 6px;
	}
}
