/* ------------------------------------------------------------ *\
	404
\* ------------------------------------------------------------ */

.section-404 {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    color: $c-green-3B;
    font-weight: 400;
    font-size: xx-large;
  }

  h1 {
    font-size: large;
  }

  h2 {
    font-size: small;
    margin-bottom: 20px;
  }

  &__title {
    width: 60%;
  }

  &__links {
    margin: 8px;
    margin-top: 50px;
  }
}
