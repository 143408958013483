/* ------------------------------------------------------------ *\
	Section-Auth
\* ------------------------------------------------------------ */

.section-auth {
  background: $c-dark-03;
  border-radius: 16px;
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  aside {
    width: 50%;
  }

  h5 {
    font-size: 12px;
    font-weight: 400;
    .terms {
      font-size: 20px;
      font-weight: 400;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
  }

  h2 {
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__terms {
    font-size: 13px;
    font-weight: 400;
    color: $c-green-3B;
    transition: color 0.3s;
    text-align: left;
  }

  &__content {
    width: 50%;
  }

  &__actions {
    display: inline-flex;
    margin-top: 20px;
  }

  &__actions2 {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid rgba(#fff, 0.25);
  }

  &__graphic {
    display: flex;
    margin-left: -109px;
  }

  &__graphic2 {
    display: flex;
    justify-content: center;
  }

  &__actions .btn {
    width: 105px;

    + .btn {
      margin-left: 10px;
    }
  }
}

@media (max-width: 767px) {
  .section-auth {
    display: none;
  }
  .footer {
    display: none;
  }
  &__actions .btn {
    display: none;
  }
}
