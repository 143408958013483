/* ------------------------------------------------------------ *\
	Articles
\* ------------------------------------------------------------ */

.articles {
  > h4 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  &__body {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
    align-items: flex-start;
    width: 100%;
  }

  .article {
    margin: 0;
  }
}

/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article {
  display: block;
  border-radius: 13px;
  overflow: hidden;
  line-height: 19.5px;
  font-size: 13px;
  cursor: pointer;
  position: relative;
  box-shadow: 0 0 0 1px transparent;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-4px);
  }

  &__link {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
  }

  &__image {
    overflow: hidden;
    position: relative;
    padding-bottom: 75%;
    transition: opacity 0.3s;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    text-align: left;
    padding: 20px;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  h3 {
    font-size: 23px;
    margin-bottom: 10px;
    line-height: 24px;
    flex: 1;
  }
}

/* ------------------------------------------------------------ *\
	Article--Single
\* ------------------------------------------------------------ */

.article--single {
  display: flex;
  align-items: flex-start;
  margin-bottom: 64px;
  width: 100%;

  @media (max-width: 767px) {
    align-items: center;
    flex-direction: column;
  }

  .article__image {
    width: 50%;
    padding-bottom: 40%;
    @media (max-width: 767px) {
      width: 100%;
      padding-bottom: 75%;
    }
  }

  .article__content {
    width: 50%;
    padding: 40px;
    @media (max-width: 767px) {
      width: 100%;
      padding: 20px;
    }
  }

  h3 {
    margin-bottom: 30px;
  }
}
