/* ------------------------------------------------------------ *\
	Mobile-Page
\* ------------------------------------------------------------ */

.mobile-page {
  text-align: center;
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  height: 100%;
  &__graphic {
    margin-bottom: 30px;
    display: flex;
  }

  &__head {
    margin-bottom: 40px;
    flex: 1;
  }

  &__foot {
    background: #1c1d2d;
    padding: 26px 0 18px;
  }

  h2 {
    font-size: 23px;
    margin-bottom: 5px;
  }

  h5 {
    font-size: 13px;
    margin-bottom: 16px;
    opacity: 0.5;
  }

  &__download svg {
    max-width: 170px;
  }
}
