/* ------------------------------------------------------------ *\
	Music-Search
\* ------------------------------------------------------------ */

.music-search {
  .search {
    max-width: 480px;
    position: relative;
    img {
      cursor: pointer;
      position: absolute;
      top: 30%;
      right: 10px;
      transform: translate(-50%, -50%);
    }
  }

  .music__field {
    margin-bottom: 40px;
  }

  .music__button {
    visibility: hidden;
    height: 0;
  }
}
