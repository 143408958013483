/* ------------------------------------------------------------ *\
	Playlist
\* ------------------------------------------------------------ */

$backgroundColor: rgba(#4d5082, 0.9);
$transitionDelay: 0.8s;

.playlist {
  width: 100%;
  @media (max-width: 767px) {
    width: 100vw;
  }
  .playlist__card {
    @media (max-width: 1025px) {
      height: 430px;
    }
    @media (max-width: 768px) {
      height: 315px;
    }
    height: 490px;
    display: flex;
    width: 100%;
    border-radius: 13px;
    overflow: hidden;
    margin-bottom: 60px;
    @media (max-width: 767px) {
      height: 100vw;
      width: 100vw;
      border-radius: 0px;
      margin-bottom: 10px;
    }
  }

  .playlist__card-aside {
    width: 50%;
    position: relative;
    overflow: hidden;
    padding: 40px;
    padding-bottom: 36%;
    @media (max-width: 767px) {
      padding: 0px;
      width: 100%;
      padding-bottom: 36%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .playlist__card-aside h5 {
    position: relative;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
  }

  .playlist__card-aside h2 {
    position: relative;
    font-size: 29px;
    font-weight: 700;
    text-shadow: 2px 2px 4px rgba(#000, 0.6);
  }

  .playlist__card-content {
    width: 50%;
    padding: 40px;
    font-size: 13px;
    line-height: 19.5px;
    position: relative;
    @media (max-width: 767px) {
      width: 100%;
      padding: 13px;
      margin-bottom: 47px;
    }
  }

  .playlist__card-content h3 {
    font-weight: 700;
    font-size: 23px;
    margin-bottom: 32px;
    line-height: 24.5px;
    @media (max-width: 767px) {
      margin-bottom: 28px;
    }
  }
  .playlist__card-content button {
    position: absolute;
    bottom: 4vh;
    padding: 20px 20px;
    border-radius: 15px;
    font-size: 13px;
  }

  &__body {
    max-width: 480px;
    margin: 0 auto;
    @media (max-width: 767px) {
      margin: 13px;
      margin-bottom: 180px;
    }
  }

  &__song {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 3px;

    + .playlist__song {
      border-top: 1px solid rgba(#fff, 0.25);
      @media (max-width: 767px) {
        border-top: 0px;
        margin-top: 13px;
        padding-top: 0px;
      }
    }
    &:hover {
      background: $backgroundColor;
      transition: $transitionDelay;
    }
  }

  &__circle-svg {
    position: absolute;
    transform: rotate(-90deg);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    stroke-dasharray: 189; /* (2PI * 30px) */
    stroke-dashoffset: 189;
    animation: offsettozero 30s linear forwards;
  }

  @keyframes offsettozero {
    to {
      stroke-dashoffset: 0;
    }
  }

  &__song.has-played {
    opacity: 0.67;
  }

  &__song-image {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    width: 94px;
    background: $backgroundColor;
    margin-right: 13px;
    @media (max-width: 767px) {
      height: 94px;
    }

    img {
      left: 0;
      top: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &__image-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__song:hover &__image-overlay {
    background-color: $backgroundColor;
  }

  &__play-icon-container {
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    display: flex;
  }

  &__song:hover &__play-icon-container {
    transition: $transitionDelay;
    margin-left: -20px;
  }

  &__pause-icon-container {
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    display: flex;
  }

  &__play-icon {
    position: absolute;
    opacity: 1;
  }
  &__song:hover &__play-icon {
    opacity: 0;
    transition: $transitionDelay;
  }

  &__play-icon-filled {
    opacity: 0;
    transition: $transitionDelay;
  }
  &__song:hover &__play-icon-filled {
    opacity: 1;
  }

  &__pause-icon {
    position: absolute;
    margin-left: -35px;
    margin-top: -10px;
  }

  &__preview {
    display: block;
    padding-left: 7px;
    opacity: 0;
    transition: $transitionDelay;
  }

  &__song:hover &__preview {
    display: block;
    opacity: 1;
  }

  &__play-icon-playing {
    position: absolute;
    display: none;
  }

  &__song-content {
    flex: 1;
  }

  &__song-content h4 {
    font-size: 16px;
    margin-bottom: 4px;
    font-weight: 700;
  }

  &__song-content h5 {
    opacity: 0.5;
    font-weight: 400;
    margin-bottom: 4px;
  }

  &__song-animation {
    display: flex;
    align-items: flex-end;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;

    span {
      height: 28px;
      width: 6px;
      border-radius: 44px;
      background: #fff;
      display: block;
      transform-origin: bottom;

      + span {
        margin-left: 6px;
      }
    }

    span:nth-child(1) {
      height: 22px;
      animation: expandUp 2.4s infinite;
    }

    span:nth-child(2) {
      height: 25px;
      animation: expandUp 2.1s infinite;
    }

    span:nth-child(3) {
      animation: expandUp 2s infinite;
    }

    span:nth-child(4) {
      animation: expandUp 1.2s infinite;
    }
  }
}
