/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
  max-width: 1440px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .header {
    justify-content: center;
    max-width: 767px;
    .btn {
      display: none;
    }
  }
}
