.now-playing {
  position: fixed;
  display: flex;
  align-items: center;

  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: 10px;
  // background: #393b5a;
  height: 80px;
  border-radius: 3px;
  z-index: 9998;
  padding: 10px;

  img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 3px;
  }
  &__metadata {
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: center;
    h3 {
      font-size: 14px;
    }
  }
}

.svg-x {
  stroke: #393b5a;
}

.svg-stroke {
  stroke: rgba(255, 255, 255, 0.75);
}

.svg-stroke-disabled {
  stroke: #656565
}

.svg-stroke:hover {
  cursor: pointer;
  stroke: #fff;
  transition: all 0.3s;
}
.svg-stroke2 {
  stroke: rgba(255, 255, 255, 0.75);
}

.svg-stroke2:hover {
  cursor: pointer;
  stroke: #fff;
  fill: #fff;
  transition: all 0.3s;
}
