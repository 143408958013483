/* ------------------------------------------------------------ *\
	Schedule
\* ------------------------------------------------------------ */

.schedule {
  position: relative;
  @media (max-width: 1025px) {
    width: 896px;
  }
  @media (max-width: 821px) {
    width: 692px;
  }
  @media (max-width: 768px) {
    width: 640px;
  }

  &:after {
    content: '';
    width: 4px;
    height: 100%;
    position: absolute;
    right: -10px;
    top: 0;
    box-shadow: 0 -4px 25px 20px rgba(#000, 0.65);
    z-index: 44;
  }

  &:before {
    content: '';
    width: 4px;
    height: 100%;
    position: absolute;
    left: -10px;
    bottom: 0;
    box-shadow: 0 4px 25px 20px rgba(#000, 0.65);
    z-index: 44;
  }

  &__day {
    + .schedule__day {
      margin-left: 20px;
      @media (max-width: 767px) {
        margin: 0px;
      }
    }
  }

  &__day-blocks {
    max-height: 711px;
    overflow-y: auto;
    padding: 0 4px 15px 0;

    @media (max-width: 767px) {
      overflow-y: unset;
      padding-right: 0px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 44px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(#fff, 0.4);
      border-radius: 44px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(#fff, 0.8);
    }
  }

  &__day > h5 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 17px;
  }

  &__block {
    padding: 24px 20px;
    border-radius: 13px;
    position: relative;
    display: flex;
    width: 100%;
    + .schedule__block {
      margin-top: 4px;
    }
  }

  &__hours {
    margin-right: 20px;
  }

  &__current {
    background: black;
    height: 2px;
    position: absolute;
    border: 0;
    border-radius: 0px;
    opacity: 0.3;
    margin-top: 10px;
  }

  &__current-icon {
    background: black;
    height: 80px;
    width: 61px;
    margin-top: -30px;
    margin-left: -2px;
    border-radius: 13px;
    position: absolute;
    opacity: 0.3;
    border: 0;
  }

  &__hours span {
    display: block;
    font-size: 13px;

    + span {
      margin-top: 20px;
    }
  }

  &__content {
    position: absolute;
    margin-left: 75px;
  }

  h4 {
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}
