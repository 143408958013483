/* ------------------------------------------------------------ *\
	Devices
\* ------------------------------------------------------------ */

.devices {
  .devices__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    // margin-bottom: 20px;
  }

  .devices__head .subtitle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 63px;
    @media (max-width: 767px) {
      padding: 0px 13px;
      margin-top: 45px;
    }
  }

  .devices__body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 21px;
    position: relative;
    @media (max-width: 767px) {
      display: block;
    }
    @media (max-width: 820px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .loader {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
  }

  &__body > h4 {
    font-size: 1vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
}

/* ------------------------------------------------------------ *\
	Device
\* ------------------------------------------------------------ */

.device {
  padding: 26px 26px 37px;
  position: relative;
  border-radius: 13px;
  display: block;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  height: 220px;

  @media (max-width: 767px) {
    margin: 13px;
  }

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // z-index: 4;
    background: 0;
    border: none;
  }

  &:hover {
    transform: translateY(-2px);
  }

  &__heart {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 8;
    background: none;
    border: 0;

    svg {
      transition: all 0.3s;
    }

    &:hover svg {
      transform: scale(1.05);
    }
  }

  &--favourite svg {
    fill: #fff;
  }

  &__head {
    margin-bottom: 40px;
    flex: 1;
  }

  &__head p {
    opacity: 0.7;
    font-size: 13px;
  }

  h3 {
    font-size: 23px;
    font-weight: 700;
    word-break: break-word;
  }

  &__body h4 {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__body h5 {
    font-size: 13px;
    font-weight: 400;
  }
}
