/* ------------------------------------------------------------ *\
	List-Results
\* ------------------------------------------------------------ */

.play-now-list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 330px;
}

/* ------------------------------------------------------------ *\
	Result-Item
\* ------------------------------------------------------------ */

.play-now-list-item {
  background: #FFFFFF;
  border-radius: 13px;
  line-height: 20px;
  border: 1px solid #AAAAAA;
  box-sizing: border-box;
  margin: 5px 15px 5px;

  p {
    text-align: center;
    font-family: 'Tondo';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    color: #AAAAAA;
    padding: 14px;
  }

  &_selected {
    @extend .play-now-list-item;
    background: #030416;
    p {
      color: #FFFFFF;
    }
  }

}

