/* ------------------------------------------------------------ *\
	Loader
\* ------------------------------------------------------------ */

.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;

	span {
		display: block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: #fff;

		+ span {
			margin-left: 5px;
		}
	}

	@for $i from 1 through 4 {
		span:nth-child(#{$i}) {
			animation: moveTop 2s (#{$i*0.2s}) infinite;
		}
	}
}
