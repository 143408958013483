/* ------------------------------------------------------------ *\
	Map
\* ------------------------------------------------------------ */

.map {
  position: relative;

  @media (max-width: 767px) {
    padding: 0px 13px;
  }

  .cluster-marker {
    color: #1b1b1b;
    background: #3baba1;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .mapboxgl-popup-content {
    padding: 0;
    border-radius: 15px;
  }

  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: #670bce;
  }

  .mapboxgl-popup-close-button {
    display: none;
  }

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__body {
    height: 40vh;
    min-height: 300px;
    background: #000;
    border-radius: 16px;
    margin-bottom: 30px;
    overflow: hidden;
  }

  &__search {
    // max-width: 463px;
    margin: 20px auto 20px;
    width: 100%;
    position: relative;
    .svg-x {
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(-50%, -50%);
    }
    @media (max-width: 767px) {
      padding: 0px 13px;
    }
  }

  a[href^="http://maps.google.com/maps"]
  {
    display: none !important;
  }

  a[href^="https://maps.google.com/maps"]
  {
    display: none !important;
  }

  .gmnoprint a,
  .gmnoprint span,
  .gm-style-cc {
    display: none;
  }

  .gmnoprint div {
    background: none !important;
  }
}
