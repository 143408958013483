/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
  font-family: $font-family-tondo;
  background: $c-dark-03;
  color: #fff;
  font-size: 13px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  select,
  select:focus,
  textarea,
  textarea:focus,
  input,
  input:focus {
    font-size: 16px;
  }
}
#hubspot-messages-iframe-container.widget-align-right {
  bottom: 80px !important;
}
#hs-feedback-ui {
  bottom: 80px !important;
}

#root
  > div
  > div
  > div.dashboard__content
  > div
  > div.page__body
  > div.map
  > div
  > div
  > div
  > div.mapboxgl-map
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-bottom-left
  > div {
  display: none !important;
}

.Toastify__toast-body > div:nth-child(2) {
  flex: 1;
  text-align: center;
}

.c-loader {
  width: 50px;
  height: 50px;
  margin-bottom: 100px;
}

.c-loader__bg {
  fill: #3baba1;
  animation: load 5s ease-in-out infinite;
  animation-fill-mode: both;
  transform-origin: center;
}

.c-loader__bar {
  fill: #ffffff;
}

.c-loader__dot {
  fill: #3baba1;
}

.c-loader__letter {
  fill: #ffffff;
}

@keyframes load {
  0% {
    fill: #3baba1;
    transform: scale(1);
  }

  20% {
    fill: #3baba1;
    transform: scale(1);
  }

  21% {
    transform: scale(0);
  }

  25% {
    fill: #c361e9;
    transform: scale(1);
  }

  45% {
    fill: #c361e9;
    transform: scale(1);
  }

  46% {
    transform: scale(0);
  }

  50% {
    fill: #0b72ce;
    transform: scale(1);
  }

  70% {
    fill: #0b72ce;
    transform: scale(1);
  }

  71% {
    transform: scale(0);
  }

  75% {
    fill: #ffbf00;
    transform: scale(1);
  }

  95% {
    fill: #ffbf00;
    transform: scale(1);
  }

  96% {
    transform: scale(0);
  }
}

a {
  text-decoration: none;
  transition: all 0.4s;
  color: inherit;
}

.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrapper > .loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

h1,
h2,
h3,
h4,
h5,
h6 {
}

.svg-icon {
  font-size: 0;
  line-height: 1;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.hidden {
  display: none !important;
}

// svg {
//   max-width: 100%;
// }
