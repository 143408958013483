/* ------------------------------------------------------------ *\
	Colors
\* ------------------------------------------------------------ */

$c-dark-03: #030416;
$c-violet-72: #72029d;
$c-violet-67: #670bce;
$c-green-3B: #3baba1;
$c-red-FF: #ff4000;
$c-yellow-FF: #ffc107;
$c-green-40: #40ff00;
$c-orange-FF: #ff4000;
$c-blue-0B: #0b11ce;
