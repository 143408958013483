/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {
  .form__body {
    margin-bottom: 20px;
  }

  textarea {
    border: 2px solid $c-green-3B;
    border-radius: 13px;
    padding: 25px;
    color: $c-dark-03;
    font-size: 13px;
  }

  .field.is-invalid input {
    border-color: $c-red-FF;
  }

  .form__actions {
    display: flex;
    align-items: center;

    .btn + .btn {
      margin-left: 16px;
    }
  }
}

@media (max-width: 767px) {
  .form {
    .form__actions {
      flex-direction: column;
      justify-content: center;
      .btn + .btn {
        margin-left: 0;
      }
    }
    .form__actions2 {
      justify-content: center;
      text-align: center;
      display: inline-flex;
      .btn + .btn {
        margin-left: 20px;
      }
    }
  }
}

/* ------------------------------------------------------------ *\
	Form-Login
\* ------------------------------------------------------------ */

.form--login {
  .form__row + .form__row {
    margin-top: 20px;
  }
}

/* ------------------------------------------------------------ *\
	Form-Reset
\* ------------------------------------------------------------ */

.form--reset {
  h4 {
    margin-bottom: 20px;
    line-height: 20px;
  }

  .form__row {
    margin-bottom: 20px;
  }
}

/* ------------------------------------------------------------ *\
	Form-Add-Device
\* ------------------------------------------------------------ */

.form--add-device {
  .field .field__input {
    height: 184px;
  }

  .btn {
    padding: 25px 34px;
  }
}

.form--add-service {
  margin-top: 40px;
  .field .field__input {
    height: 184px;
  }

  p {
    font-size: 14px;
    margin-bottom: 14px !important;
  }

  .btn {
    padding: 25px 34px;
  }
}

/* ------------------------------------------------------------ *\
	Form-Track-Feedback
\* ------------------------------------------------------------ */

.form--track-feedback {
  max-height: 880px;
  form {
    padding-bottom: 20px;
  }

  .form__row + .form__row {
    margin-top: 20px;
  }

  .btn {
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
  }

  .btn--primary {
    max-width: 220px;
    margin: 20px auto;
    display: block;
  }

  textarea {
    display: block;
    width: 100%;
    height: 155px;
  }

  .form__body.has-selected {
    .emoji-field:not(.is-checked) {
      filter: grayscale(1);
    }
  }
}
