/* ------------------------------------------------------------ *\
	Btn
\* ------------------------------------------------------------ */

.btn {
  appearance: none;
  border: none;
  background: transparent;
  line-height: 1;
  border-radius: 16px;
}

/* Primary */

.btn--primary {
  background: $c-green-3B;
  color: #fff;
  padding: 19px 55px;
  border: 1px solid $c-green-3B;
  font-weight: 700;
  transition: color 0.3s, background 0.3s;
  font-size: 13px;

  &:hover {
    background: #fff;
    color: $c-green-3B;
  }
}

.btn--secondary {
  color: #fff;
  padding: 19px 55px;
  font-weight: 700;
  transition: color 0.3s, background 0.3s;
  font-size: 13px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 13px;

  &:hover {
    background: #fff;
    color: $c-green-3B;
    border: 1px solid $c-green-3B;
  }
}

.btn--blue {
  background: $c-blue-0B;
  color: #fff;
  padding: 19px 55px;
  border: 1px solid $c-blue-0B;
  font-weight: 700;
  transition: color 0.3s, background 0.3s;
  font-size: 13px;

  &:hover {
    background: #fff;
    color: $c-blue-0B;
  }
}

.btn--loading {
  background: #fff;
  color: $c-green-3B;
  padding: 19px 55px;
  border: 1px solid $c-green-3B;
  font-weight: 700;
  transition: color 0.3s, background 0.3s;
  font-size: 13px;
}

/* As-Link */

.btn--as-link {
  color: $c-green-3B;
  font-size: 13px;
  border-radius: 0;
  padding: 0;
  transition: color 0.3s;
  text-align: left;

  &:hover {
    color: #fff;
  }
}

/* White */

.btn--white {
  background: #fff;
  padding: 15px 22px;
  color: $c-dark-03;
  font-size: 16px;
  font-weight: 700;
  border: 2px solid #fff;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: none;
    color: #fff;
  }
}

.btn--white2 {
  background: #fff;
  padding: 15px 22px;
  color: $c-green-3B;
  font-size: 16px;
  font-weight: 700;
  border: 2px solid #fff;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: none;
    color: #fff;
  }
}

/* Icon */

.btn--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $c-green-3B;
  border: 1px solid $c-green-3B;
  padding: 16px 10px;
  transition: background 0.3s, color 0.3s;
  height: 49px;

  &:hover {
    background: #fff;
    color: $c-green-3B;
  }

  &:hover svg path {
    fill: $c-green-3B;
  }

  svg path {
    transition: fill 0.44s;
  }

  svg {
    display: inline-block;
    margin-right: 10px;
  }
}

.btn--sonos {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid $c-green-3B;
  padding: 16px 10px;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: #fff;
    color: $c-green-3B;
  }

  &:hover svg path {
    fill: $c-green-3B;
  }

  svg path {
    transition: fill 0.44s;
  }

  svg {
    display: inline-block;
    margin-right: 10px;
  }
}

.logout-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  @media (min-width: 767px) {
    display: none;
  }
}
.noHover{
  pointer-events: none;
}
