/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
	font-family: 'Tondo';
	src: url('../../assets/fonts/Tondo-Bold.woff2') format('woff2'),
		url('../../assets/fonts/Tondo-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Tondo';
	src: url('../../assets/fonts/Tondo-Regular.woff2') format('woff2'),
		url('../../assets/fonts/Tondo-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
