/* ------------------------------------------------------------ *\
	Info-Card
\* ------------------------------------------------------------ */

.switcher {
  @media (min-width: 767px) {
    position: absolute;
    right: 0px;
    top: 0px;
  }


}

.react-switch-bg {
  border: 1px solid;
  background: transparent !important;
}

.mobile-default-nav {
  @media (min-width: 767px) {
    display: none;
  }
  list-style: none;
  height: 77px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #000;

  position: fixed;
  bottom: 0;
  z-index: 99;
  width: 100%;

  a {
    display: flex;
    align-items: center;
    flex-direction: column;
    opacity: 0.7;
  }
  .is-current {
    opacity: 1;
  }
}

.mobile-group-svg {
  @media (min-width: 767px) {
    display: none;
  }
}

.desktop-group-svg {
  @media (max-width: 767px) {
    display: none;
  }
}

.get-started {
  @media (max-width: 767px) {
    display: none;
  }
  background-color: #0b72ce;
  width: 980px;
  height: 488px;
  border-radius: 13px;
  margin-bottom: 50px;
  display: flex;
}

.info-card {
  display: flex;
  border-radius: 13px;
  overflow: hidden;
  height: 600px;
  margin-bottom: 105px;
  justify-content: space-around;

  .letter {
    left: -500px;
    position: absolute;
    width: 700%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 160% 160%;
    filter: opacity(0.2);
    background-position: center center;
    @media (max-width: 767px) {
      width: 700%;
    }
  }

  @media (max-width: 767px) {
    border-radius: 0px;
    position: relative;
    margin-bottom: 10px;
  }

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--reversed {
    flex-direction: row-reverse;
  }

  &--profileHeight {
    @media (max-width: 767px) {
      height: 300px;
    }
  }

  &__image {
    margin-right: 50px;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      margin: 0px;
      position: absolute;
      width: 189px;
      height: 350px;
      bottom: -29px;
      right: -50px;
    }

    img {
      width: 221px;
      height: 88%;
      object-fit: contain;

      @media (max-width: 767px) {
        height: auto;
      }
    }
  }

  &--showOnMobile {
    @media (max-width: 767px) {
      display: none;
    }
  }

  &__content {
    @media (max-width: 767px) {
      margin: 0px;
      width: 100%;
      padding: 30px 10px 30px 15px;
    }
    margin-left: 50px;
    padding: 40px;
    width: 50%;
    position: relative;
  }

  .available-text {
    @media (max-width: 767px) {
      padding-right: 130px;
    }
  }

  h3 {
    @media (max-width: 767px) {
      padding-right: 130px;
    }
  }

  h2 {
    @media (max-width: 767px) {
      padding-right: 25px;
    }
  }

  h1 {
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 30px;
    }
    font-size: 50px;
    color: #090e4f;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 32px;
  }

  p {
    margin-bottom: 24px;
    font-size: 13px;
  }
}
