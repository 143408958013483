/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */
input::placeholder,
textarea::placeholder {
  color: inherit;
  opacity: 0.5;
}

input:-webkit-autofill {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

/* ------------------------------------------------------------ *\
	Field
\* ------------------------------------------------------------ */

.custom-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 57px;
  margin-bottom: 47px;
  background: 0 !important;
  border: 2px solid $c-green-3B !important;
  border-radius: 13px !important;
  padding: 0 26px !important;
  color: #fff !important;
  font-size: 13px;
  transition: background 0.2s, color 0.2s, border-color 0.2s;
}

.custom-dropdown-menu {
  background-color: #393b5a !important;
  border: 2px solid $c-green-3B !important;
  border-bottom-right-radius: 13px;
  border-bottom-left-radius: 13px;
}

.Dropdown-option {
  color: #fff !important;
}

.Dropdown-option:hover {
  // color: #000 !important;
  background-color: $c-green-3B !important;
}
.Dropdown-option.is-selected {
  background-color: $c-green-3B !important;
}

.Dropdown-root.is-open .custom-dropdown {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.arrow-dropdown {
  position: relative;
  top: 6px;
}

.field {
  .field__input {
    display: block;
    width: 100%;
    height: 57px;
    background: 0;
    border: 2px solid $c-green-3B;
    border-radius: 13px;
    padding: 0 26px;
    transition: background 0.2s, color 0.2s, border-color 0.2s;
    text-indent: 20px;

    &:focus {
      background: #fff;
      color: $c-dark-03;
    }
  }

  .field__textarea {
    display: block;
    width: 100%;
    height: 184px;
    background: 0;
    border: 2px solid $c-green-3B;
    border-radius: 13px;
    padding: 26px;
    transition: background 0.2s, color 0.2s;
    resize: none;
    color: #fff;

    &:focus {
      background: #fff;
      color: $c-dark-03;
    }
  }

  .field__error-message {
    color: $c-red-FF;
    margin-left: 10px;
    margin-top: 5px;
    display: block;
  }
}
